import { connect } from 'react-redux'

import { compose } from 'recompose'
import Component from './components'
import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../redux/selectors/rewrite/users'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../redux/selectors/rewrite/itemSkus'
import UiContainer from '../../../common-components/UiContainer'
import { getAddressOfCustomer } from '../../../redux/selectors/addresses'
import { ADDRESS_TYPES_DEFAULT_SHIPPING } from '../../../constants'
import {
  createFieldArraysAndCustomersFieldsDropdownOptions,
} from '../../ReportShipments/common/ReportAndEditShipments/components/util/fieldArraysAndCustomersFieldsDropdownOptions'
import { getEntireSlice as getEntireParentChildLinksSlice } from '../../../redux/selectors/rewrite/parentChildLinks'
import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../constants/formAndApiUrlConfig/reportOutboundShipments'

const mapStateToProps = (state, { match, customerId, operatingContractBrewerCustomerId }) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const itemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const businessUnitId = getCustomerProp(state, customerId, 'businessUnitId')
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const defaultShippingAddress = getAddressOfCustomer({
    state,
    customerId,
    currentlyActiveAddressesOnly: true, // CODE_COMMENTS_112 CODE_COMMENTS_116
    addressType: ADDRESS_TYPES_DEFAULT_SHIPPING,
  })
  const { country: defaultCountry } = defaultShippingAddress
  const customerType = entireCustomersSlice[customerId].customerType
  const sources = createFieldArraysAndCustomersFieldsDropdownOptions({
    customerId,
    customerType,
    operatingContractBrewerCustomerId,
    formType: 'outbounds',
    isEditForm: false,
    fieldArrayNameIfIsEditForm: REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    itemSkuIds,
    match,
  })?.[0].customersFieldsDropdownOptions || []

  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entireItemSkusSlice,
    itemSkuIds,
    businessUnitId,
    defaultCountry,
    sources,
  }
}


const RequestNewDistributor = compose(
  UiContainer(() => ({
    headerText: 'Add New Location',
    headerProps: { className: 'segment-header-bottom-margin' },
    containerId: 'Request New Distributor Section', // strip spaces from page title
  })),
)(Component)

export default connect(
  mapStateToProps,
)(RequestNewDistributor)

