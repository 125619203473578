import { combineReducers } from 'redux'


import {
  REDUCER_NAMES_ENTITIES_ADDRESSES,
  REDUCER_NAMES_ENTITIES_CUSTOMER_ADDRESS_LINKS,
  REDUCER_NAMES_ENTITIES_COLLAR_PLATES,
  REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS,
  REDUCER_NAMES_ENTITIES_CUSTOMERS,
  REDUCER_NAMES_ENTITIES_PARENT_CHILD_LINKS,
  REDUCER_NAMES_ENTITIES_CONTRACTS,
  REDUCER_NAMES_ENTITIES_RELATIONSHIPS,
  REDUCER_NAMES_ENTITIES_USERS,
  REDUCER_NAMES_ENTITIES_CONTACTS,
  REDUCER_NAMES_ENTITIES_ITEM_SKUS,
  REDUCER_NAMES_ENTITIES_CUSTOMER_CONTACT_LINKS,
  REDUCER_NAMES_ENTITIES_SUBSIDIARIES,
} from '../../../constants'

import addressesReducer from './addresses'
import customerAddressLinksReducer from './customerAddressLinks'
import collarPlatesReducer from './collarPlates'
import customerCollarPlateLinksReducer from './customerCollarPlateLinks'
import customersReducer from './customers'
import parentChildLinksReducer from './parentChildLinks'
import ContractsReducer from './contracts'
import relationshipsReducer from './relationships'
import usersReducer from './users'
import contactsReducer from './contacts'
import itemSkusReducer from './itemSkus'
import customerContactLinksReducer from './customerContactLinks'
import subsidiariesReducer from './subsidiaries'


const entitiesReducers = {
  [REDUCER_NAMES_ENTITIES_ADDRESSES]: addressesReducer,
  [REDUCER_NAMES_ENTITIES_CUSTOMER_ADDRESS_LINKS]: customerAddressLinksReducer,
  [REDUCER_NAMES_ENTITIES_COLLAR_PLATES]: collarPlatesReducer,
  [REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS]: customerCollarPlateLinksReducer,
  [REDUCER_NAMES_ENTITIES_CUSTOMERS]: customersReducer,
  [REDUCER_NAMES_ENTITIES_PARENT_CHILD_LINKS]: parentChildLinksReducer,
  [REDUCER_NAMES_ENTITIES_CONTRACTS]: ContractsReducer,
  [REDUCER_NAMES_ENTITIES_RELATIONSHIPS]: relationshipsReducer,
  [REDUCER_NAMES_ENTITIES_USERS]: usersReducer,
  [REDUCER_NAMES_ENTITIES_CONTACTS]: contactsReducer,
  [REDUCER_NAMES_ENTITIES_ITEM_SKUS]: itemSkusReducer,
  [REDUCER_NAMES_ENTITIES_CUSTOMER_CONTACT_LINKS]: customerContactLinksReducer,
  [REDUCER_NAMES_ENTITIES_SUBSIDIARIES]: subsidiariesReducer,
}

export default combineReducers(entitiesReducers)
