import React, { Component } from 'react'
import { Button, Input, Form, Grid } from 'semantic-ui-react'

import isBoolean_ from 'lodash/isBoolean'


import { Trans } from 'react-i18next'
import InputNoAutoComplete from '../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import DismissableMessage from '../../../common-components/DismissableMessage'

import SaveBankAccountInfo from '../SaveBankAccountInfo'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
} from '../../../constants'

import {
  CYBER_SOURCE_PAYMENTS_URL,
} from '../../../config'

import {
  getIsStringRepresentationOfMoney,
  formatStringOrNumberToMoney,
  convertStringRepresentationOfUSAMoneyToNumber,
} from '../../../utils'


const PAY_BALANCE_IN_FULL_BUTTON = 'PAY_BALANCE_IN_FULL_BUTTON'
const MAKE_PARTIAL_PAYMENT_BUTTON = 'MAKE_PARTIAL_PAYMENT_BUTTON'

export default class extends Component {
  state = {
    partialAmountInput: '',
    isInputGreaterThanBalance: false,
    // Will be set after these things happen: 1) the user clicks the "Pay
    // Balance" button; 2) the payBalance.js saga fetches the secret-key
    // signature from the backend and returns it to this function along with the
    // other needed field names.
    fieldNamesOfFormToBeSubmittedToPaymentGateway: null,

    saveNewBankAccountInfoIfNoBankAccountInfoYetSaved: true,
    useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved: 'saved',
    updateSavedBankAccountIfUseNewBankAccount: true,

    isFetchingFieldNames: false,
    didFetchingFieldNamesSucceed: false,
    didFetchingFieldNamesFail: false,
    whichButtonDidUserClickOn: null,
    remittanceDetail: '',
  }

  onChangePartialAmountInput = e => {
    const partialAmountInput = e.target.value
    const inputNoCommas = partialAmountInput.replace(/,/g, '')

    if (partialAmountInput === '') {
      this.setState({
        partialAmountInput,
      })
      return
    }

    if (
      getIsStringRepresentationOfMoney({
        str: inputNoCommas,
        zeroAllowed: true,
        negativeValuesAllowed: false,
        dollarSignAtBeginningAllowed: false,
        dollarSignAtBeginningRequired: false,
        commasAllowedIfTheyreInTheRightPlaces: false,
        okToHaveDecimalBeTheLastChar: true,
        okToHaveOnlyOneDigitAfterDecimal: true,
        okToHaveMoreThanTwoDigitsAfterDecimal: false,
      })
    ) {
      // Allow the user to enter an input greater than the current balance but
      // disable the partial payment button and show an error message.
      const inputNum = convertStringRepresentationOfUSAMoneyToNumber({ str: inputNoCommas })
      const isInputGreaterThanBalance = inputNum > this.props.balance

      this.setState({
        partialAmountInput: inputNoCommas,
        isInputGreaterThanBalance,
      })
    } else {
      // if it's not a valid representation of money, don't change the value
      this.setState({
        partialAmountInput: this.state.partialAmountInput,
      })
    }
  }

  onFocusPartialPaymentField = () => {
    // remove commas
    const inputNoCommas = this.state.partialAmountInput.replace(/,/g, '')

    this.setState({
      partialAmountInput: inputNoCommas,
    })
  }

  onBlurPartialPaymentField = () => {
    // add commas
    const inputStringWithCommas = formatStringOrNumberToMoney({
      value: this.state.partialAmountInput,
      includeSign: false,
    })

    this.setState({
      partialAmountInput: inputStringWithCommas,
    })
  }


  onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved = explicitBoolArg => {
    const newValue = isBoolean_(explicitBoolArg)
      ? explicitBoolArg
      : !this.state.saveNewBankAccountInfoIfNoBankAccountInfoYetSaved
    this.setState({
      saveNewBankAccountInfoIfNoBankAccountInfoYetSaved: newValue,
    })
  }
  onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved = (e, { value }) => {
    this.setState({
      useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved: value,
    })
  }
  onToggleUpdateSavedBankAccountIfUseNewBankAccount = explicitBoolArg => {
    const newValue = isBoolean_(explicitBoolArg)
      ? explicitBoolArg
      : !this.state.updateSavedBankAccountIfUseNewBankAccount
    this.setState({
      updateSavedBankAccountIfUseNewBankAccount: newValue,
    })
  }


  onPayFullBalance = () => {
    this.onPayBalance({
      amountStr: this.props.balance,
      whichButtonDidUserClickOn: PAY_BALANCE_IN_FULL_BUTTON,
      remittanceDetail: this.state.remittanceDetail,
    })
  }


  onPayPartialBalance = () => {
    this.onPayBalance({
      amountStr: this.state.partialAmountInput,
      whichButtonDidUserClickOn: MAKE_PARTIAL_PAYMENT_BUTTON,
      remittanceDetail: this.state.remittanceDetail,
    })
  }

  onPayBalance = ({
    amountStr,
    whichButtonDidUserClickOn,
    remittanceDetail,
  }) => {
    const amount = convertStringRepresentationOfUSAMoneyToNumber({ str: amountStr })
    if (amount === 0) { return }
    this.setState({
      isFetchingFieldNames: true,
      whichButtonDidUserClickOn,
    })
    const fieldNamesPromise = this.props.onSubmit({
      amount,
      saveNewBankAccountInfoIfNoBankAccountInfoYetSaved:
        this.state.saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
      useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved:
        this.state.useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
      updateSavedBankAccountIfUseNewBankAccount:
        this.state.updateSavedBankAccountIfUseNewBankAccount,
      remittanceDetail,
    })
    const sagaResultPromise = fieldNamesPromise.then(
      // handle response
      fieldNames => {
        this.setState({
          fieldNamesOfFormToBeSubmittedToPaymentGateway: fieldNames,
          didFetchingFieldNamesSucceed: true,
          isFetchingFieldNames: false,
        })
      },
      // handle error
      () => {
        this.setState({
          didFetchingFieldNamesFail: true,
          isFetchingFieldNames: false,
        })
      },
    )
    // We need to put the programmatic click in this new promise handler rather
    // than in the previous promise handler, because in between the two promise
    // handlers, a render cycle will happen and the hidden form (see below) will
    // render. Had we put the programmatic click in the previous promise
    // handler, there would be no submit button to click.
    sagaResultPromise.then(() => {
      // Keep in mind that this .then() function _always_ runs, no matter
      // whether the saga called the resolve() or reject() method. So we need to
      // make sure to not programmatically click the submit button if the saga
      // called the reject() method.
      if (this.state.didFetchingFieldNamesSucceed) {
        // This is how to programmatically submit a form in React. See
        // CODE_COMMENTS_152
        this.submitButtonOfFormToBeSubmittedToPaymentGateway.click()
      }
    })
  }

  setFormState = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Form>
            <Grid className="semitight-grid-rows">
              <Grid.Row columns={2} style={{ marginTop: '1rem' }}>
                <Grid.Column style={{ textAlign: 'right', height: 'fit-content', top: '7px' }}>
                  <Trans ns="pagelabels" i18nKey="payBalance.Remittance Detail">Remittance Detail: </Trans>
                </Grid.Column>
                <Grid.Column style={{ textAlign: 'left' }}>
                  <InputNoAutoComplete
                    name="remittanceDetail"
                    value={this.state.remittanceDetail}
                    onChange={e => this.setFormState(e)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {
              this.props.balance <= 0 &&
              <div style={{ textAlign: 'center', marginTop: '1em' }}>
                <span style={{ color: 'green' }}>
                  <Trans ns="pagelabels" i18nKey="payBalance.No payment is currently due.">
                    No payment is currently due.
                  </Trans>
                </span>
              </div>
            }
            <Input type="hidden" name="amount" value={this.props.balance} />
            <Button
          color={
            (
              (
                this.state.isFetchingFieldNames
                || this.state.didFetchingFieldNamesSucceed
              )
              && this.state.whichButtonDidUserClickOn === PAY_BALANCE_IN_FULL_BUTTON
            )
            || this.props.balance <= 0
              ? 'grey'
              : 'blue'
          }
          fluid
          size="large"
          onClick={this.onPayFullBalance}
          loading={
            (this.state.isFetchingFieldNames || this.state.didFetchingFieldNamesSucceed) &&
            this.state.whichButtonDidUserClickOn === PAY_BALANCE_IN_FULL_BUTTON
          }
          disabled={
            this.state.isFetchingFieldNames
            || this.state.didFetchingFieldNamesSucceed
            || this.props.balance <= 0
          }
            >
              <Trans ns="pagelabels" i18nKey="payBalance.Pay Balance in Full">
                Pay Balance in Full
              </Trans>
            </Button>
            {/*
          We use this div in place of Semantic UI's <Divider> for 2 reasons:
          1. We want to control the margin of the divider (make it tighter);
          2. We don't want the horizontal lines on either side of the "OR" text
             because that divides the content too starkly; it gives the
             impression that the Bank Account Info below the
             "Make Partial Payment" button is connected explicitly to the
             "Make Partial Payment" button.
        */}
            <div className="custom-divider">
              <Trans ns="pagelabels" i18nKey="payBalance.OR">OR</Trans>
            </div>
            {/*
          The only reason we wrap this in a Semantic UI <Form> component
          is so that when the user clicks Enter after typing in their
          partial balance amount, the "Make Partial Payment" button will
          be clicked.
        */}

            <div className="field-with-error-message">
              <InputNoAutoComplete
              name="amount"
              value={this.state.partialAmountInput}
              onChange={this.onChangePartialAmountInput}
              onBlur={this.onBlurPartialPaymentField}
              onFocus={this.onFocusPartialPaymentField}
              action={{
                // props for the <Button> object
                color: (
                  (
                    (
                      this.state.whichButtonDidUserClickOn === MAKE_PARTIAL_PAYMENT_BUTTON &&
                      (this.state.isFetchingFieldNames || this.state.didFetchingFieldNamesSucceed)
                    )
                    || (
                      this.state.isInputGreaterThanBalance
                    )
                    || (
                      this.props.balance <= 0
                    )
                  )
                    ? 'grey'
                    : 'blue'
                ),
                content: (<span><Trans ns="pagelabels" i18nKey="payBalance.Make Partial Payment">Make Partial Payment</Trans></span>),
                onClick: this.onPayPartialBalance,
                loading: (
                  (this.state.isFetchingFieldNames || this.state.didFetchingFieldNamesSucceed) &&
                  this.state.whichButtonDidUserClickOn === MAKE_PARTIAL_PAYMENT_BUTTON
                ),
                disabled: (
                  this.state.isFetchingFieldNames
                  || this.state.didFetchingFieldNamesSucceed
                  || this.state.isInputGreaterThanBalance
                  || this.props.balance <= 0
                ),
              }}
              icon={this.props.currency === 'EUR' ?'euro': 'dollar'}
              iconPosition="left"
              placeholder="Amount to Pay"
              fluid
              className="bold-text-input-field"
              disabled={
                this.state.isFetchingFieldNames
                || this.state.didFetchingFieldNamesSucceed
              }
              />
              <div style={{ textAlign: 'center' }}>
                <small style={{ fontStyle: 'italic', color: 'grey' }}>
                  <Trans ns="pagelabels" i18nKey="payBalance.Partial payments will be applied oldest open invoice to newest">
                    Partial payments will be applied oldest open invoice to newest
                  </Trans>
                </small>
              </div>
              <div><small
              style={{
                display: this.state.isInputGreaterThanBalance ? 'block' : 'none',
              }}
              >
                <Trans ns="pagelabels" i18nKey="payBalance.Partial payment value is greater than total balance">
                  Partial payment value is greater than total balance
                </Trans>
              </small></div>
              <SaveBankAccountInfo
              customerId={this.props.customerId}
              saveNewBankAccountInfoIfNoBankAccountInfoYetSaved={
                this.state.saveNewBankAccountInfoIfNoBankAccountInfoYetSaved
              }
              onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved={
                this.onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved
              }
              useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved={
                this.state.useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved
              }
              onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved={
                this.onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved
              }
              updateSavedBankAccountIfUseNewBankAccount={
                this.state.updateSavedBankAccountIfUseNewBankAccount
              }
              onToggleUpdateSavedBankAccountIfUseNewBankAccount={
                this.onToggleUpdateSavedBankAccountIfUseNewBankAccount
              }
              />
            </div>
          </Form>
          {this.state.didFetchingFieldNamesFail && !this.state.isFetchingFieldNames &&
          <DismissableMessage
            style={{ marginBottom: '15px' }}
            error
            content={DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}
          />
        }
          {
          // Why this hidden form? Because we need a form that acts just like a
          // regular, old-school HTML form, i.e. one in which, when the
          // <form>'s' submit button is clicked, the browser navigates to the
          // <form>'s 'action' URL at the same time it sends a POST of the
          // form's <input>s. I wasn't able to figure out any clever way to do
          // this using React and React Router. Fortunately, though, React's
          // elements are just DOM elements under the hood, so this just renders
          // an actual (hidden) regular, old-school HTML form after the user
          // clicks the "Pay Balance" in the displayed form above. Then it
          // programmatically clicks the submit button on this hidden form,
          // which performs the desired POST and redirect.
          this.state.fieldNamesOfFormToBeSubmittedToPaymentGateway &&
          <form
            action={CYBER_SOURCE_PAYMENTS_URL}
            method="post"
            style={{ display: 'none' }} // Hide it!
          >
            {Object.entries(this.state.fieldNamesOfFormToBeSubmittedToPaymentGateway).map(([name, value]) => (
              <input type="hidden" readOnly key={name} name={name} value={value} />
            ))}
            <input
              // this ref is so we can programmatically submit the form. See
              // CODE_COMMENTS_152
              ref={f => { this.submitButtonOfFormToBeSubmittedToPaymentGateway = f }}
              type="submit"
              name="submit"
              value="Submit"
            />
          </form>
        }
        </div>
      </React.Fragment>
    )
  }
}
