import preliminaryInfoSagas from './preliminaryInfo'
import itemSkusSagas from './itemSkus'
import inboundUnacknowledgedShipmentsSagas from './inboundUnacknowledgedShipments'
import ordersOfInboundUnacknowledgedShipmentsSagas from './ordersOfInboundUnacknowledgedShipments'
import customerRepresentativesSagas from './customerRepresentatives'
import inboundShipmentsOfSpecificKegOrderSagas from './inboundShipmentsOfSpecificKegOrder'
import collarPlatesSagas from './collarPlates'
import customerUsersSagas from './customerUsers'
import contactsSagas from './contacts'
import customerBalancesSagas from './customerBalances'
import bankAccountInfoSagas from './bankAccountInfo'
import reportShipmentsFormsPotentialDuplicatesSagas from './reportShipmentsFormsPotentialDuplicates'
import reportedInventoryVsCalculatedInventorySagas from './reportedInventoryVsCalculatedInventory'
import subsidiariesSagas from './subsidiaries'

// CODE_COMMENTS_11
export default [
  ...preliminaryInfoSagas,
  ...itemSkusSagas,
  ...inboundUnacknowledgedShipmentsSagas,
  ...ordersOfInboundUnacknowledgedShipmentsSagas,
  ...customerRepresentativesSagas,
  ...inboundShipmentsOfSpecificKegOrderSagas,
  ...collarPlatesSagas,
  ...customerUsersSagas,
  ...contactsSagas,
  ...customerBalancesSagas,
  ...bankAccountInfoSagas,
  ...reportShipmentsFormsPotentialDuplicatesSagas,
  ...reportedInventoryVsCalculatedInventorySagas,
  ...subsidiariesSagas,
]
