export const LOGIN = 'LOGIN'
// called AUTHENTICATION_FAILURE rather than LOGIN_FAILURE because it is used in
// other parts of the app besides the login page
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS'
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE'
export const LOGOUT = 'LOGOUT'
export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN'
export const SET_CATASTROPHIC_FAILURE_HAPPENED = 'SET_CATASTROPHIC_FAILURE_HAPPENED'
export const UNSET_CATASTROPHIC_FAILURE_HAPPENED = 'UNSET_CATASTROPHIC_FAILURE_HAPPENED'

export const SAVE_CURRENT_USER = 'SAVE_CURRENT_USER'
// For when a user with "Manage Users" privileges updates their own basic info
// (name, deskPhone, etc.)
export const UPDATE_BASIC_INFO_OF_CURRENT_USER = 'UPDATE_BASIC_INFO_OF_CURRENT_USER'
// For when a user with "Manage Users" privileges updates their own permissions
export const UPDATE_PERMISSIONS_OF_CURRENT_USER = 'UPDATE_PERMISSIONS_OF_CURRENT_USER'

// CODE_COMMENTS_221
export const SAVE_EMPLOYEE_CURRENT_USER = 'SAVE_EMPLOYEE_CURRENT_USER'
// These aren't actually used when an employee is using the web app, but they
// need to be created nonetheless because the reducer factory responsible for
// creating both the currentUser reducer and the employeeCurrentReducer needs
// these values.
export const UPDATE_BASIC_INFO_OF_EMPLOYEE_CURRENT_USER = 'UPDATE_BASIC_INFO_OF_EMPLOYEE_CURRENT_USER'
export const UPDATE_PERMISSIONS_OF_EMPLOYEE_CURRENT_USER = 'UPDATE_PERMISSIONS_OF_EMPLOYEE_CURRENT_USER'

export const PRIVATE_FETCH = 'PRIVATE_FETCH'

export const PERFORM_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN = 'PERFORM_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN'
export const SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED = 'SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED'
export const UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED = 'UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED'
export const SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED = 'SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED'
export const UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED = 'UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED'

export const FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS = 'FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS'
export const FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER = 'FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER'
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER'
export const FETCH_CUSTOMER_CHILDREN = 'FETCH_CUSTOMER_CHILDREN'
export const SAVE_PARENT_TO_CHILDREN_NORMALIZED_LINKS = 'SAVE_PARENT_TO_CHILDREN_NORMALIZED_LINKS'
export const FETCH_CUSTOMER_ADDRESSES = 'FETCH_CUSTOMER_ADDRESSES'
export const FETCH_CUSTOMER_CONTRACTS = 'FETCH_CUSTOMER_CONTRACTS'
export const FETCH_CUSTOMER_RELATED_TO_INFO = 'FETCH_CUSTOMER_RELATED_TO_INFO'
export const FETCH_CUSTOMER_RELATED_FROM_INFO = 'FETCH_CUSTOMER_RELATED_FROM_INFO'
export const FETCH_COLLAR_PLATES_INFO = 'FETCH_COLLAR_PLATES_INFO'
export const FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS = 'FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS' // for the Manage Users form
export const FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS = 'FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS' // For when an employee logs in and operates for a customer user
export const FETCH_CUSTOMER_USER_PERMISSIONS = 'FETCH_CUSTOMER_USER_PERMISSIONS' // for the Manage Users form
export const FETCH_ITEM_SKUS = 'FETCH_ITEM_SKUS'
export const FETCH_CONTACTS = 'FETCH_CONTACTS' // for the Manage Users form
export const FETCH_CUSTOMER_BALANCES = 'FETCH_CUSTOMER_BALANCES'
export const FETCH_STORED_BANK_ACCOUNT_INFO = 'FETCH_STORED_BANK_ACCOUNT_INFO'
export const FETCH_DELETE_STORED_BANK_ACCOUNT_INFO = 'FETCH_DELETE_STORED_BANK_ACCOUNT_INFO'
export const FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY = 'FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY'
export const FETCH_SUBSIDIARIES = 'FETCH_SUBSIDIARIES'


export const SAVE_ADDRESSES = 'SAVE_ADDRESSES'
export const SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS = 'SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS'
export const SAVE_COLLAR_PLATES = 'SAVE_COLLAR_PLATES'
export const SAVE_CUSTOMER_TO_COLLAR_PLATES_NORMALIZED_LINKS = 'SAVE_CUSTOMER_TO_COLLAR_PLATES_NORMALIZED_LINKS'
export const SAVE_CUSTOMERS = 'SAVE_CUSTOMERS'
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER'
export const SAVE_CUSTOMER_CONTRACTS = 'SAVE_CUSTOMER_CONTRACTS'
export const SAVE_CUSTOMER_CONTRACT = 'SAVE_CUSTOMER_CONTRACT'
export const SAVE_RELATIONSHIPS = 'SAVE_RELATIONSHIPS'
export const SAVE_RELATIONSHIP = 'SAVE_RELATIONSHIP'
export const SAVE_USER = 'SAVE_USER'
export const SAVE_USERS = 'SAVE_USERS'
export const SAVE_CUSTOMER_USER_PERMISSIONS = 'SAVE_CUSTOMER_USER_PERMISSIONS'
export const SAVE_ITEM_SKUS = 'SAVE_ITEM_SKUS'
export const SAVE_CONTACT = 'SAVE_CONTACT'
export const SAVE_CONTACTS = 'SAVE_CONTACTS'
export const SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS = 'SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS'
export const SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK = 'SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK'
export const DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS = 'DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS'
export const DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK = 'DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK'
export const SAVE_CUSTOMER_BALANCES = 'SAVE_CUSTOMER_BALANCES'
export const SAVE_STORED_BANK_ACCOUNT_INFO = 'SAVE_STORED_BANK_ACCOUNT_INFO'
export const DELETE_STORED_BANK_ACCOUNT_INFO_FROM_REDUX_STORE = 'DELETE_STORED_BANK_ACCOUNT_INFO_FROM_REDUX_STORE'
export const SAVE_SUBSIDIARIES = 'SAVE_SUBSIDIARIES'


// part of preliminary info
export const FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS = 'FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS'
export const SAVE_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER = 'SAVE_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER'
export const DELETE_SPECIFIC_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER = 'DELETE_SPECIFIC_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER'
// CODE_COMMENTS_283
export const FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS = 'FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS'
export const SAVE_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER = 'SAVE_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER'

// CODE_COMMENTS_241
export const FETCH_ALL_UNIVERSAL_CUSTOMER_REPRESENTATIVES = 'FETCH_ALL_UNIVERSAL_CUSTOMER_REPRESENTATIVES'
export const FETCH_UNIVERSAL_CUSTOMER_REPRESENTATIVE = 'FETCH_UNIVERSAL_CUSTOMER_REPRESENTATIVE'
export const SAVE_UNIVERSAL_CUSTOMER_REPRESENTATIVE = 'SAVE_UNIVERSAL_CUSTOMER_REPRESENTATIVE'

export const ENABLE_LOGOUT_BUTTON = 'ENABLE_LOGOUT_BUTTON'
export const DISABLE_LOGOUT_BUTTON = 'DISABLE_LOGOUT_BUTTON'

export const START_KEEP_USER_LOGGED_IN_THREAD_ONCE_WE_KNOW_WE_HAVE_AN_UNEXPIRED_REFRESH_TOKEN = 'START_KEEP_USER_LOGGED_IN_THREAD_ONCE_WE_KNOW_WE_HAVE_AN_UNEXPIRED_REFRESH_TOKEN'

export const START_FETCH_CUSTOMER_REPRESENTATIVE_QUEUE_THREAD = 'START_FETCH_CUSTOMER_REPRESENTATIVE_QUEUE_THREAD'
export const FETCH_INFO_OF_ALL_REPRESENTATIVES_OF_ONE_CUSTOMER = 'FETCH_INFO_OF_ALL_REPRESENTATIVES_OF_ONE_CUSTOMER'
export const FETCH_CUSTOMER_REPRESENTATIVE_INFO = 'FETCH_CUSTOMER_REPRESENTATIVE_INFO'

export const SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER =
  'SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER'
export const UNSET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER =
  'UNSET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER'

// CODE_COMMENTS_238
export const START_POLL_TO_GET_OUT_OF_MAINTENANCE_MODE_THREAD = 'START_POLL_TO_GET_OUT_OF_MAINTENANCE_MODE_THREAD'
export const START_MAINTENANCE_SCHEDULE_FETCHER_THREAD = 'START_MAINTENANCE_SCHEDULE_FETCHER_THREAD'
export const START_SET_MAINTENANCE_MODE_NOTIFICATION_FLAG_THREAD = 'START_SET_MAINTENANCE_MODE_NOTIFICATION_FLAG_THREAD'
export const START_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE_THREAD = 'START_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE_THREAD'
export const SET_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503 = 'SET_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503'
export const FETCH_MAINTENANCE_SCHEDULE = 'FETCH_MAINTENANCE_SCHEDULE'
export const SAVE_MAINTENANCE_SCHEDULE = 'SAVE_MAINTENANCE_SCHEDULE'
export const SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE = 'SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE'
export const SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED = 'SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED'

// CODE_COMMENTS_282
export const START_LATEST_WEB_APP_VERSION_FETCHER_THREAD = 'START_LATEST_WEB_APP_VERSION_FETCHER_THREAD'
export const START_HARD_REFRESH_THREAD_TO_DOWNLOAD_LATEST_WEB_APP_VERSION = 'START_HARD_REFRESH_THREAD_TO_DOWNLOAD_LATEST_WEB_APP_VERSION'
export const SAVE_LATEST_WEB_APP_VERSION_STRING = 'SAVE_LATEST_WEB_APP_VERSION_STRING'
export const INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH = 'INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH'
export const CLEAR_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH = 'CLEAR_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH'
export const HARD_REFRESH_TO_DOWNLOAD_LATEST_WEB_APP_VERSION = 'HARD_REFRESH_TO_DOWNLOAD_LATEST_WEB_APP_VERSION'
export const SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY = 'SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY'

/*
 * *****************************************************************************
 * Forms
 * *****************************************************************************
*/

export const FETCH_ORDER_KEGS = 'FETCH_ORDER_KEGS'
export const EDIT_OR_CANCEL_ORDER_KEGS = 'EDIT_OR_CANCEL_ORDER_KEGS'
export const FETCH_WAREHOUSE_PICKUP_LOCATIONS = 'FETCH_WAREHOUSE_PICKUP_LOCATIONS'

export const FETCH_ORDER_COLLARS = 'FETCH_ORDER_COLLARS'
export const FETCH_EDIT_COLLAR_ORDER = 'FETCH_EDIT_COLLAR_ORDER'

export const FETCH_REPORT_SHIPMENTS = 'FETCH_REPORT_SHIPMENTS'
export const FETCH_REPORT_INDIVIDUAL_SHIPMENT = 'FETCH_REPORT_INDIVIDUAL_SHIPMENT'
export const FETCH_EDIT_OUTBOUND_SHIPMENT_REPORT = 'FETCH_EDIT_OUTBOUND_SHIPMENT_REPORT'
export const FETCH_UPDATE_SCHEDULED_SHIPMENT = 'FETCH_UPDATE_SCHEDULED_SHIPMENT'
export const FETCH_UPDATE_SCHEDULED_SHIPMENT_SHIP_DATE = 'FETCH_UPDATE_SCHEDULED_SHIPMENT_SHIP_DATE'

export const FETCH_REPORT_NO_MOVEMENTS = 'FETCH_REPORT_NO_MOVEMENTS'
export const FETCH_REPORT_INDIVIDUAL_NO_MOVEMENT = 'FETCH_REPORT_INDIVIDUAL_NO_MOVEMENT'

export const FETCH_ACKNOWLEDGE_INBOUND_SHIPMENTS = 'FETCH_ACKNOWLEDGE_INBOUND_SHIPMENTS'
export const FETCH_ACKNOWLEDGE_INDIVIDUAL_INBOUND_SHIPMENT = 'FETCH_ACKNOWLEDGE_INDIVIDUAL_INBOUND_SHIPMENT'

export const FETCH_PAY_CUSTOMER_BALANCE = 'FETCH_PAY_CUSTOMER_BALANCE'

export const FETCH_REPORT_INVENTORY = 'FETCH_REPORT_INVENTORY'
export const FETCH_EDIT_INVENTORY_REPORT = 'FETCH_EDIT_INVENTORY_REPORT'
export const FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM = 'FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM' // CODE_COMMENTS_267
export const FETCH_INFO_NEEDED_TO_CONSTRUCT_UNSUBSCRIPTION_NO_LOGIN_REQUIRED_FORM = 'FETCH_INFO_NEEDED_TO_CONSTRUCT_UNSUBSCRIPTION_NO_LOGIN_REQUIRED_FORM'
export const FETCH_UNSUBSCRIPTION = 'FETCH_UNSUBSCRIPTION'

export const FETCH_ADD_NEW_CONTACT = 'FETCH_ADD_NEW_CONTACT'
export const FETCH_EDIT_CONTACT = 'FETCH_EDIT_CONTACT'
export const FETCH_DELETE_CONTACT = 'FETCH_DELETE_CONTACT'
export const FETCH_EDIT_MASTER_CONTACT = 'FETCH_EDIT_MASTER_CONTACT'

export const FETCH_KEG_INDIVIDUAL_ORDER = 'FETCH_KEG_INDIVIDUAL_ORDER'
export const FETCH_KEG_ORDER_HISTORY = 'FETCH_KEG_ORDER_HISTORY'
export const SAVE_KEG_ORDER_HISTORY = 'SAVE_KEG_ORDER_HISTORY'
export const SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM = 'SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM'
export const FETCH_DELETE_KEG_ORDER = 'FETCH_DELETE_KEG_ORDER' // deleting from the backend
export const DELETE_KEG_ORDER_HISTORY_ITEM = 'DELETE_KEG_ORDER_HISTORY_ITEM'

export const FETCH_INVOICE_HISTORY = 'FETCH_INVOICE_HISTORY'
export const SAVE_INVOICE_HISTORY = 'SAVE_INVOICE_HISTORY'
export const FETCH_DOWNLOAD_INVOICE = 'FETCH_DOWNLOAD_INVOICE'

export const FETCH_INVENTORY_HISTORY = 'FETCH_INVENTORY_HISTORY'
export const SAVE_INVENTORY_HISTORY = 'SAVE_INVENTORY_HISTORY'
export const SAVE_INVENTORY_HISTORY_OF_MULTIPLE_CUSTOMERS = 'SAVE_INVENTORY_HISTORY_OF_MULTIPLE_CUSTOMERS'
export const SAVE_INVENTORY_HISTORY_ITEMS_OF_MULTIPLE_CUSTOMERS_WITHOUT_DELETING_ANY_EXISTING_HISTORY_ITEMS = 'SAVE_INVENTORY_HISTORY_ITEMS_OF_MULTIPLE_CUSTOMERS_WITHOUT_DELETING_ANY_EXISTING_HISTORY_ITEMS'
export const SAVE_SINGLE_INVENTORY_HISTORY_ITEM = 'SAVE_SINGLE_INVENTORY_HISTORY_ITEM'

export const FETCH_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER = 'FETCH_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER'
export const SAVE_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER = 'SAVE_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER'

export const FETCH_COLLAR_ORDER_HISTORY = 'FETCH_COLLAR_ORDER_HISTORY'
export const SAVE_COLLAR_ORDER_HISTORY = 'SAVE_COLLAR_ORDER_HISTORY'
export const SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM = 'SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM'
export const FETCH_DELETE_COLLAR_ORDER = 'FETCH_DELETE_COLLAR_ORDER' // deleting from the backend
export const DELETE_COLLAR_ORDER_HISTORY_ITEM = 'DELETE_COLLAR_ORDER_HISTORY_ITEM'

export const FETCH_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY = 'FETCH_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY'
export const SAVE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY = 'SAVE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY'
export const SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM = 'SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM'
export const DELETE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM = 'DELETE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM'

export const FETCH_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY = 'FETCH_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY'
export const FETCH_SCHEDULED_SHIPMENT_HISTORY = 'FETCH_SCHEDULED_SHIPMENT_HISTORY'
export const SCHEDULED_SHIPMENT = 'scheduledShipments'
export const SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY = 'SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY'
export const SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM = 'SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM'
export const DELETE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM = 'DELETE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM'

// CODE_COMMENTS_232
export const FETCH_KEG_FILL_CONTRACTEE_HISTORY = 'FETCH_KEG_FILL_CONTRACTEE_HISTORY'
export const SAVE_KEG_FILL_CONTRACTEE_HISTORY = 'SAVE_KEG_FILL_CONTRACTEE_HISTORY'
export const SAVE_SINGLE_KEG_FILL_CONTRACTEE_HISTORY_ITEM = 'SAVE_SINGLE_KEG_FILL_CONTRACTEE_HISTORY_ITEM'
export const DELETE_KEG_FILL_CONTRACTEE_HISTORY_ITEM = 'DELETE_KEG_FILL_CONTRACTEE_HISTORY_ITEM'

// CODE_COMMENTS_205
export const FETCH_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY = 'FETCH_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY'
export const SAVE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY = 'SAVE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY'
export const SAVE_SINGLE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM = 'SAVE_SINGLE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM'
export const DELETE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM = 'DELETE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM'

// CODE_COMMENTS_205
export const FETCH_INBOUND_FULL_KEG_SHIPMENT_HISTORY = 'FETCH_INBOUND_FULL_KEG_SHIPMENT_HISTORY'
export const SAVE_INBOUND_FULL_KEG_SHIPMENT_HISTORY = 'SAVE_INBOUND_FULL_KEG_SHIPMENT_HISTORY'
export const SAVE_SINGLE_INBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM = 'SAVE_SINGLE_INBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM'
export const DELETE_INBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM = 'DELETE_INBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM'

export const FETCH_DELETE_REPORTED_KEG_SHIPMENT = 'FETCH_DELETE_REPORTED_KEG_SHIPMENT'

export const FETCH_NO_MOVEMENTS_HISTORY = 'FETCH_NO_MOVEMENTS_HISTORY'
export const SAVE_NO_MOVEMENTS_HISTORY = 'SAVE_NO_MOVEMENTS_HISTORY'
export const SAVE_NO_MOVEMENTS_HISTORY_ITEM = 'SAVE_NO_MOVEMENTS_HISTORY_ITEM'
export const DELETE_NO_MOVEMENTS_HISTORY_ITEM = 'DELETE_NO_MOVEMENTS_HISTORY_ITEM'

export const FETCH_EDIT_USER_INFO_OF_ONE_USER = 'FETCH_EDIT_USER_INFO_OF_ONE_USER'
export const FETCH_EDIT_PERMISSIONS_OF_ONE_USER = 'FETCH_EDIT_PERMISSIONS_OF_ONE_USER'

export const FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_STATE = 'FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_STATE'
export const FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_CITY = 'FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_CITY'
export const FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS = 'FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS'
export const FETCH_REQUEST_NEW_DISTRIBUTOR_COUNTRY = 'FETCH_REQUEST_NEW_DISTRIBUTOR_COUNTRY'
export const FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_DISTRIBUTOR = 'FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_DISTRIBUTOR'

export const SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES = 'SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES'

// CODE_COMMENTS_244
export const FETCH_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES = 'FETCH_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES'
export const SAVE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES = 'SAVE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES'
export const SAVE_MULTIPLE_SHIPMENTS_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES = 'SAVE_MULTIPLE_SHIPMENTS_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES'
export const DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES = 'DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES'
export const DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES = 'DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES' // CODE_COMMENTS_254

export const SAVE_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA_OF_ONE_CUSTOMER = 'SAVE_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA_OF_ONE_CUSTOMER'

/*
 * *****************************************************************************
 * Public Forms
 * *****************************************************************************
*/

export const FETCH_FORGOT_PASSWORD_ENTER_EMAIL = 'FETCH_FORGOT_PASSWORD_ENTER_EMAIL'
export const FETCH_FORGOT_PASSWORD_RESET_PW = 'FETCH_FORGOT_PASSWORD_RESET_PW'
export const SAVE_FORGOT_PASSWORD_INPUT_EMAIL_AND_RESET_CODE_SENT_TO = 'SAVE_FORGOT_PASSWORD_INPUT_EMAIL_AND_RESET_CODE_SENT_TO'

export const FETCH_FETCH_CONFIRM_ACCOUNT = 'FETCH_FETCH_CONFIRM_ACCOUNT'
export const FETCH_RESEND_CONFIRMATION_CODE_TO_NEW_USER = 'FETCH_RESEND_CONFIRMATION_CODE_TO_NEW_USER'


/*
 * *****************************************************************************
 * Fetch statuses
 * *****************************************************************************
*/

export const FETCH_PRELIM_CUSTOMER_INFO_REQUEST = 'FETCH_PRELIM_CUSTOMER_INFO_REQUEST'
export const FETCH_PRELIM_CUSTOMER_INFO_SUCCESS = 'FETCH_PRELIM_CUSTOMER_INFO_SUCCESS'
export const FETCH_PRELIM_CUSTOMER_INFO_FAILURE = 'FETCH_PRELIM_CUSTOMER_INFO_FAILURE'
export const FETCH_PRELIM_CUSTOMER_INFO_CLEAR = 'FETCH_PRELIM_CUSTOMER_INFO_CLEAR'

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST'
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE'

export const FETCH_CUSTOMER_CHILDREN_REQUEST = 'FETCH_CUSTOMER_CHILDREN_REQUEST'
export const FETCH_CUSTOMER_CHILDREN_SUCCESS = 'FETCH_CUSTOMER_CHILDREN_SUCCESS'
export const FETCH_CUSTOMER_CHILDREN_FAILURE = 'FETCH_CUSTOMER_CHILDREN_FAILURE'

export const FETCH_CUSTOMER_ADDRESSES_REQUEST = 'FETCH_CUSTOMER_ADDRESSES_REQUEST'
export const FETCH_CUSTOMER_ADDRESSES_SUCCESS = 'FETCH_CUSTOMER_ADDRESSES_SUCCESS'
export const FETCH_CUSTOMER_ADDRESSES_FAILURE = 'FETCH_CUSTOMER_ADDRESSES_FAILURE'

export const FETCH_CUSTOMER_CONTRACTS_REQUEST = 'FETCH_CUSTOMER_CONTRACTS_REQUEST'
export const FETCH_CUSTOMER_CONTRACTS_SUCCESS = 'FETCH_CUSTOMER_CONTRACTS_SUCCESS'
export const FETCH_CUSTOMER_CONTRACTS_FAILURE = 'FETCH_CUSTOMER_CONTRACTS_FAILURE'

export const FETCH_CUSTOMER_RELATED_TO_INFO_REQUEST = 'FETCH_CUSTOMER_RELATED_TO_INFO_REQUEST'
export const FETCH_CUSTOMER_RELATED_TO_INFO_SUCCESS = 'FETCH_CUSTOMER_RELATED_TO_INFO_SUCCESS'
export const FETCH_CUSTOMER_RELATED_TO_INFO_FAILURE = 'FETCH_CUSTOMER_RELATED_TO_INFO_FAILURE'
export const FETCH_CUSTOMER_RELATED_TO_INFO_CLEAR = 'FETCH_CUSTOMER_RELATED_TO_INFO_CLEAR'

export const FETCH_CUSTOMER_RELATED_FROM_INFO_REQUEST = 'FETCH_CUSTOMER_RELATED_FROM_INFO_REQUEST'
export const FETCH_CUSTOMER_RELATED_FROM_INFO_SUCCESS = 'FETCH_CUSTOMER_RELATED_FROM_INFO_SUCCESS'
export const FETCH_CUSTOMER_RELATED_FROM_INFO_FAILURE = 'FETCH_CUSTOMER_RELATED_FROM_INFO_FAILURE'

export const FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST = 'FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST'
export const FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS = 'FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS'
export const FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE = 'FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE'

// CODE_COMMENTS_283
export const FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST = 'FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST'
export const FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS = 'FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS'
export const FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE = 'FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE'

export const FETCH_COLLAR_PLATES_INFO_REQUEST = 'FETCH_COLLAR_PLATES_INFO_REQUEST'
export const FETCH_COLLAR_PLATES_INFO_SUCCESS = 'FETCH_COLLAR_PLATES_INFO_SUCCESS'
export const FETCH_COLLAR_PLATES_INFO_FAILURE = 'FETCH_COLLAR_PLATES_INFO_FAILURE'

export const FETCH_CUSTOMER_USERS_REQUEST = 'FETCH_CUSTOMER_USERS_REQUEST'
export const FETCH_CUSTOMER_USERS_SUCCESS = 'FETCH_CUSTOMER_USERS_SUCCESS'
export const FETCH_CUSTOMER_USERS_FAILURE = 'FETCH_CUSTOMER_USERS_FAILURE'

// For when an employee logs in and operates for a customer user
export const FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_REQUEST = 'FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_REQUEST'
export const FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS = 'FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS'
export const FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_FAILURE = 'FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_FAILURE'
export const FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_CLEAR = 'FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_CLEAR'

export const FETCH_CUSTOMER_USER_PERMISSIONS_REQUEST = 'FETCH_CUSTOMER_USER_PERMISSIONS_REQUEST'
export const FETCH_CUSTOMER_USER_PERMISSIONS_SUCCESS = 'FETCH_CUSTOMER_USER_PERMISSIONS_SUCCESS'
export const FETCH_CUSTOMER_USER_PERMISSIONS_FAILURE = 'FETCH_CUSTOMER_USER_PERMISSIONS_FAILURE'

export const FETCH_ITEM_SKUS_REQUEST = 'FETCH_ITEM_SKUS_REQUEST'
export const FETCH_ITEM_SKUS_SUCCESS = 'FETCH_ITEM_SKUS_SUCCESS'
export const FETCH_ITEM_SKUS_FAILURE = 'FETCH_ITEM_SKUS_FAILURE'

export const FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_QUEUE = 'FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_QUEUE'
export const FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_REQUEST = 'FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_REQUEST'
export const FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_SUCCESS = 'FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_SUCCESS'
export const FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_FAILURE = 'FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_FAILURE'
export const FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_CLEAR = 'FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_CLEAR'

export const FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_REQUEST = 'FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_REQUEST'
export const FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_SUCCESS = 'FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_SUCCESS'
export const FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_FAILURE = 'FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_FAILURE'

export const FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_QUEUE = 'FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_QUEUE'
export const FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_REQUEST = 'FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_REQUEST'
export const FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_SUCCESS = 'FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_SUCCESS'
export const FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_FAILURE = 'FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_FAILURE'

export const FETCH_STATUSES_FORMS_QUEUE = 'FETCH_STATUSES_FORMS_QUEUE'
export const FETCH_STATUSES_FORMS_REQUEST = 'FETCH_STATUSES_FORMS_REQUEST'
export const FETCH_STATUSES_FORMS_SUCCESS = 'FETCH_STATUSES_FORMS_SUCCESS'
export const FETCH_STATUSES_FORMS_FAILURE = 'FETCH_STATUSES_FORMS_FAILURE'
export const FETCH_STATUSES_FORMS_CLEAR = 'FETCH_STATUSES_FORMS_CLEAR'

export const FETCH_STATUSES_PUBLIC_FORMS_REQUEST = 'FETCH_STATUSES_PUBLIC_FORMS_REQUEST'
export const FETCH_STATUSES_PUBLIC_FORMS_SUCCESS = 'FETCH_STATUSES_PUBLIC_FORMS_SUCCESS'
export const FETCH_STATUSES_PUBLIC_FORMS_FAILURE = 'FETCH_STATUSES_PUBLIC_FORMS_FAILURE'
export const FETCH_STATUSES_PUBLIC_FORMS_CLEAR = 'FETCH_STATUSES_PUBLIC_FORMS_CLEAR'

export const FETCH_STATUSES_CUSTOMER_BALANCES_REQUEST = 'FETCH_STATUSES_CUSTOMER_BALANCES_REQUEST'
export const FETCH_STATUSES_CUSTOMER_BALANCES_SUCCESS = 'FETCH_STATUSES_CUSTOMER_BALANCES_SUCCESS'
export const FETCH_STATUSES_CUSTOMER_BALANCES_FAILURE = 'FETCH_STATUSES_CUSTOMER_BALANCES_FAILURE'

export const FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_REQUEST = 'FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_REQUEST'
export const FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_SUCCESS = 'FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_SUCCESS'
export const FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_FAILURE = 'FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_FAILURE'

export const FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_REQUEST = 'FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_REQUEST'
export const FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_SUCCESS = 'FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_SUCCESS'
export const FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_FAILURE = 'FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_FAILURE'

export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST'
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE'

export const FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_REQUEST = 'FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_REQUEST'
export const FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_SUCCESS = 'FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_SUCCESS'
export const FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_FAILURE = 'FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_FAILURE'

export const FETCH_SUBSIDIARIES_REQUEST = 'FETCH_SUBSIDIARIES_REQUEST'
export const FETCH_SUBSIDIARIES_SUCCESS = 'FETCH_SUBSIDIARIES_SUCCESS'
export const FETCH_SUBSIDIARIES_FAILURE = 'FETCH_SUBSIDIARIES_FAILURE'


/*
 * *****************************************************************************
 * Batched Actions: CODE_COMMENTS_148
 * *****************************************************************************
*/

export const SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME = 'SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME'
export const FETCH_WH_EMAIL_CONTENT = 'FETCH_WH_EMAIL_CONTENT'
export const FETCH_CUSTOMER_HOLIDAY_DAYS = 'FETCH_CUSTOMER_HOLIDAY_DAYS'
export const SAVE_CUSTOMER_HOLIDAY_LIST = 'SAVE_CUSTOMER_HOLIDAY_LIST'
export const FETCH_BOL_PDF_DOWNLOAD = 'FETCH_BOL_PDF_DOWNLOAD'
