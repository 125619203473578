import React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createRelativePath } from '../../../../utils'
import { URL_PATHS } from '../../../../constants'

const OrderAgain = props => {
  const { match } = props
  const { t: translate } = useTranslation('common')
  // const path = goUpInUrlPath(match?.url || '', 1)
  // const relativePath = { ...match, url: path }
  return (
    <Link to={{
      pathname: createRelativePath(match, URL_PATHS.orderKegs.path),
      info: { ...props },
    }}
    >
      <Button
        type="button"
        color="green"
      >
        {translate('Order Again')}
      </Button>
    </Link>
  )
}

export default OrderAgain
