import { call, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'

import has_ from 'lodash/has'

import flow_ from 'lodash/fp/flow'
import filterFp_ from 'lodash/fp/filter'
import mapFp_ from 'lodash/fp/map'


import { privateFetch } from '../../fetch'

import {
  FETCH_ACKNOWLEDGE_INDIVIDUAL_INBOUND_SHIPMENT,
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
} from '../../../actions/actionTypes'

import {
  FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,
  FIELD_NAME_ID,
  FIELD_NAME_DATE_RECEIVED,
  FIELD_NAME_GOOD_PALLETS,
  FIELD_NAME_BAD_PALLETS,
  FIELD_NAME_FOREIGN_KEGS,
} from '../../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'
import {
  SHIPMENT_STATUS_ACKNOWLEDGED,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'
import {
  ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
  ITEM_SKU_IDS_GOOD_PALLETS,
  ITEM_SKU_IDS_BAD_PALLETS,
} from '../../../../constants'

import {
  getEntireSlice as getInbUnackedShipmentObject,
} from '../../../selectors/inboundUnacknowledgedShipments'

import {
  mergeLineItemObjectsArrays,
} from '../../../selectors/rewrite/itemSkus'
import {
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../../selectors/rewrite/subsidiaries'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'

import {
  saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
} from '../sharedUtil/ackInboundShipmentsAndReportOutboundShipments'

import {
  getDoesAckInboundShipmentsApiErrorSayShipmentHasAlreadyBeenAcked,
  getArePalletsFieldsRenderedForThisRow,
} from '../../../../features/AcknowledgeInboundShipments/util'

import createAction from '../../../actions/createAction'

import {
  getIndividualShipmentApiUrl,
  formatDateForApiCall,
} from '../../../../utils'

export function* fetchAcknowledgeIndividualInboundShipment(action) {
  const {
    dispatchFetchStatuses,
    rowValues,
    rowIndex,
  } = action.payload
  dispatchFetchStatuses(createAction(
    FETCH_STATUSES_FORMS_REQUEST,
    { target: [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex] },
  ))
  const apiRequestBody = yield call(createApiRequestBody, action.payload)

  let response
  try {
    response = yield call(
      privateFetch,
      { path: getIndividualShipmentApiUrl(rowValues[FIELD_NAME_ID]), data: apiRequestBody, method: 'PUT' },
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { objToSave: response.data, ...action.payload })
}


function* doSuccess({
  customerId,
  dispatchFetchStatuses,
  rowIndex,
  objToSave,
}) {
  yield call(
    saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
    {
      customerId,
      fieldArrayName: FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,
      shipmentObj: objToSave,
    },
  )
  dispatchFetchStatuses(createAction(
    FETCH_STATUSES_FORMS_SUCCESS,
    { target: [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex] },
  ))
}


function* doFailure({
  error,
  dispatchFetchStatuses,
  rowIndex,
}) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_FORMS_FAILURE,
        target: [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex],
      }),
      customDispatch: dispatchFetchStatuses,
      onlyLogErrorIf: props => !getDoesAckInboundShipmentsApiErrorSayShipmentHasAlreadyBeenAcked(props),
    },
  )
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_ACKNOWLEDGE_INDIVIDUAL_INBOUND_SHIPMENT, fetchAcknowledgeIndividualInboundShipment],
]


// helper functions

function* createApiRequestBody({
  customerId,
  itemSkuIds,
  rowValues,
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  entireSubsidiariesSlice,
  subsidiaryId,
  rowIndex,
}) {
  const shipmentUuid = rowValues[FIELD_NAME_ID]
  // when we acknowledge inbound shipments, we use a PUT, so we'll send back a
  // modified copy of the entire object we receive from the GET
  const shipmentObj = yield select(getInbUnackedShipmentObject, customerId, shipmentUuid)


  const dateReceived = formatDateForApiCall({
    date: rowValues[FIELD_NAME_DATE_RECEIVED],
  })

  const lineItems = flow_(
    filterFp_(itemSkuId => has_(rowValues, itemSkuId)),
    mapFp_(itemSkuId => ({
      itemSkuId,
      quantity: rowValues[itemSkuId],
      linkType: 'ACKED',
    })),
    lineItems_ => ([
      ...lineItems_,
      ...(getIsSubsidiaryATypeThatShouldTrackForeignKegs({
        entireSubsidiariesSlice,
        subsidiaryId,
      }) ? [{
          itemSkuId: ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
          quantity: rowValues[FIELD_NAME_FOREIGN_KEGS],
          linkType: 'ACKED',
        }]
        : []
      ),
    ]),
  )(itemSkuIds)

  // Some shipments don't have goodPallets or badPallets fields (e.g. local
  // shipments and all shipments of KegCraft customers).
  const arePalletsFieldsRenderedForThisRow = getArePalletsFieldsRenderedForThisRow({
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
    entireSubsidiariesSlice,
    subsidiaryId,
    rowIndex,
  })
  if (arePalletsFieldsRenderedForThisRow) {
    const goodPalletsQty = Number(rowValues[FIELD_NAME_GOOD_PALLETS])
    const badPalletsQty = Number(rowValues[FIELD_NAME_BAD_PALLETS])
    lineItems.push({
      itemSkuId: ITEM_SKU_IDS_GOOD_PALLETS,
      linkType: 'ACKED',
      quantity: goodPalletsQty,
    })
    lineItems.push({
      itemSkuId: ITEM_SKU_IDS_BAD_PALLETS,
      linkType: 'ACKED',
      quantity: badPalletsQty,
    })
  }


  return assembleApiRequestBodyObj({
    shipmentObj,
    dateReceived,
    lineItems,
  })
}


function assembleApiRequestBodyObj({
  shipmentObj,
  dateReceived,
  lineItems,
}) {
  return {
    ...shipmentObj,
    // CODE_COMMENTS_47
    dateReceived,
    lineItems: mergeLineItemObjectsArrays(shipmentObj.lineItems, lineItems),
    status: SHIPMENT_STATUS_ACKNOWLEDGED,
    dateAcknowledged: formatDateForApiCall({
      date: moment(),
    }),
  }
}
